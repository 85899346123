<template>
  <div>whitelist_users</div>
</template>

<script>
  export default {
    name: "whitelist_users",
    data() {
      return {};
    },
  };
</script>

<style scoped></style>
